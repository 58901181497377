












































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswer: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'To avoid the solvent bumps when boiling',
          value: 'avoidBumping',
        },
        {
          text: 'To maximize heating',
          value: 'maximizeHeating',
        },
        {
          text: 'To easily add more solvent',
          value: 'easilyAddMoreSolvent',
        },
        {
          text: 'To minimize the loss of solvent',
          value: 'minimizeEvaporation',
        },
      ],
      // éè
      optionsFr: [
        {
          text: 'Pour éviter les éclaboussures du solvant en ébullition',
          value: 'avoidBumping',
        },
        {
          text: 'Pour maximiser le chauffage du solvant',
          value: 'maximizeHeating',
        },
        {
          text: 'Pour ajouter plus facilement plus de solvant',
          value: 'easilyAddMoreSolvent',
        },
        {
          text: "Pour minimiser l'évaporation du solvant",
          value: 'minimizeEvaporation',
        },
      ],
    };
  },
};
